import React from 'react';
import {
  Button,
  Text,
  Dropdown,
  Option,
  Subtitle2Stronger,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  Body1Strong,
  tokens,
} from '@fluentui/react-components';
import type { OptionOnSelectData, DropdownProps } from '@fluentui/react-components';
import { Save24Regular } from '@fluentui/react-icons';
import * as microsoftTeams from '@microsoft/teams-js';

import * as settingsDataActions from '../../store/actions/settings-data-action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isLoadingSelector, msTeamsContextSelector } from '../../store/selectors/common-selectors';
import {
  presenceActivitiesSelector,
  userPresenceDefaultMappingsSelector,
} from '../../store/selectors/settings-data-selectors';
import UserPresenceMapping from '../../models/user-presence-mapping';
import { settings } from '../../i18n/all';
import MsTeamsActivityType from '../../models/ms-teams-activity-type.enum';
import { cloneDeep } from 'lodash';
import PresenceActivity from '../../models/presence-activity';
import { isMobile } from 'react-device-detect';

interface PresenceActivityDefaultMappingProps {
  presenceActivities: PresenceActivity[];
  userPresenceDefaultMappings: UserPresenceMapping[];
  msTeamsContext: microsoftTeams.app.Context | null;
  actions: any;
  isLoading: boolean;
}

interface PresenceActivityDefaultMappingState {
  mappings: { [type: string]: PresenceActivity };
}

export class PresenceActivityDefaultMapping extends React.Component<
  PresenceActivityDefaultMappingProps,
  PresenceActivityDefaultMappingState,
  DropdownProps
> {
  mainTypes = [
    MsTeamsActivityType.Available,
    MsTeamsActivityType.Busy,
    MsTeamsActivityType.DoNotDisturb,
    MsTeamsActivityType.BeRightBack,
    MsTeamsActivityType.Away,
    //When user select "Appear offline" Microsoft Graph sends "Offline" value as activity
    MsTeamsActivityType.OffWork,
  ];
  constructor(props: PresenceActivityDefaultMappingProps) {
    super(props);
    this.props.actions.loadPresenceActivitiesDefaultMapping(this.props.msTeamsContext?.user?.id);
    this.state = { mappings: {} };
  }

  onSaveData() {
    const items = cloneDeep(this.props.userPresenceDefaultMappings);
    items.forEach((item) => {
      const newValue = this.state.mappings[item.teamsPresenceActivity.toString()];
      if (newValue) {
        item.telepoPresenceActivityId = newValue.id;
        item.telepoPresenceAvailable = newValue.available;
      }
    });
    this.setState({ mappings: {} });
    this.props.actions.savePresenceActivitiesDefaultMapping(items);
  }

  renderItem(m: UserPresenceMapping, allItems: PresenceActivity[]) {
    const optionSelectHandle = (ev: any, data: OptionOnSelectData) => {
      const selected = allItems.find((pa) => pa.id === data.optionValue?.split('/')[0]);
      if (selected) {
        let mappings = this.state.mappings;
        mappings[data.optionValue?.split('/')[1]!] = selected;
      }
      // for (let i = 0; i < data.items.length; i++) {
      //   data.items[i].selected = data.items[i].key === data.value.key;
      // }
    };
    return (
      <>
        <TableRow key={m.teamsPresenceActivity} style={{ border: 'none' }}>
          <TableCell colSpan={3}>{settings.getString(MsTeamsActivityType[m.teamsPresenceActivity])}</TableCell>
          <TableCell colSpan={3}>
            {!this.props.isLoading && (
              <Dropdown
                style={{ width: '100%' }}
                onOptionSelect={optionSelectHandle}
                defaultValue={allItems.find((pa) => pa.id === m.telepoPresenceActivityId)?.name}
              >
                {allItems.map((option) => (
                  <Option
                    key={option.id}
                    value={option.id + '/' + m.teamsPresenceActivity.toString()}
                    text={option.name}
                  >
                    {option.name}
                  </Option>
                ))}
              </Dropdown>
            )}
          </TableCell>
          <TableCell colSpan={6}></TableCell>
        </TableRow>
      </>
    );
  }

  render() {
    if (!this.props.presenceActivities) {
      return '';
    }
    const allItems = [
      new PresenceActivity('', false, ' ', ''), //
      ...this.props.presenceActivities,
    ];

    allItems.sort((a, b) => (a.name || '').localeCompare(b.name || ''));

    const sortByTeamsActivity = (a: UserPresenceMapping, b: UserPresenceMapping) =>
      this.mainTypes.indexOf(a.teamsPresenceActivity) > this.mainTypes.indexOf(b.teamsPresenceActivity) ? 1 : -1;

    return (
      !!this.props.userPresenceDefaultMappings?.length && (
        <>
          <Table size="extra-small">
            <TableHeader>
              <TableRow style={{ border: 'none' }}>
                <TableHeaderCell colSpan={12}>
                  <Subtitle2Stronger>{settings.defaultMappingSettings}</Subtitle2Stronger>
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
          </Table>
          <Table size="medium">
            <TableHeader>
              <TableRow style={{ border: 'none' }}>
                <TableHeaderCell colSpan={3}>
                  <Body1Strong>{settings.msTeamsManualPresence}</Body1Strong>
                </TableHeaderCell>
                <TableHeaderCell colSpan={9}>
                  <Body1Strong>{settings.pbxPresence}</Body1Strong>
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {this.props.userPresenceDefaultMappings
                .filter((upm) => this.mainTypes.findIndex((mt) => upm.teamsPresenceActivity === mt) !== -1)
                .sort(sortByTeamsActivity)
                .map((m) => {
                  return this.renderItem(m, allItems);
                })}
            </TableBody>
          </Table>
          <Table size="medium">
            <TableHeader>
              <TableRow style={{ border: 'none' }}>
                <TableHeaderCell colSpan={12}>
                  <Body1Strong>{settings.msTeamsAutoPresence}</Body1Strong>
                </TableHeaderCell>
              </TableRow>
              <TableRow style={{ border: 'none' }}>
                <TableHeaderCell colSpan={12}>
                  <Text style={{ fontStyle: 'italic' }}>{settings.msTeamsAutoPresenceHint}</Text>
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {this.props.userPresenceDefaultMappings
                .filter((upm) => this.mainTypes.findIndex((mt) => upm.teamsPresenceActivity === mt) === -1)
                .map((m) => this.renderItem(m, allItems))}
            </TableBody>
          </Table>
          <Table size="extra-small">
            <TableHeader>
              <TableRow style={{ border: 'none' }}>
                <TableHeaderCell colSpan={isMobile ? 1 : 3}></TableHeaderCell>
                <TableHeaderCell colSpan={isMobile ? 11 : 9}>
                  <Button
                    id="save"
                    size="medium"
                    icon={<Save24Regular />}
                    content={settings.save}
                    iconPosition="before"
                    appearance="primary"
                    onClick={() => this.onSaveData()}
                  >
                    {settings.save}
                  </Button>
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
          </Table>
        </>
      )
    );
  }
}

const mapStateToProps = (state: any) => ({
  presenceActivities: presenceActivitiesSelector(state),
  userPresenceDefaultMappings: userPresenceDefaultMappingsSelector(state),
  msTeamsContext: msTeamsContextSelector(state),
  isLoading: isLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...settingsDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PresenceActivityDefaultMapping);
