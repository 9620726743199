import { Button, Dropdown, Option, Subtitle2Stronger } from '@fluentui/react-components';
import type { OptionOnSelectData } from '@fluentui/react-components';
import { Flex } from '@fluentui/react-migration-v0-v9';
import { ArrowSync16Filled } from '@fluentui/react-icons';
import * as microsoftTeams from '@microsoft/teams-js';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setRole } from '../../i18n/all';
import PresenceRole from '../../models/presence-role';
import * as userDataActions from '../../store/actions/user-data-action';
import { msTeamsContextSelector } from '../../store/selectors/common-selectors';
import { userRolesInfoSelector, userAliasesSelector } from '../../store/selectors/user-data-selectors';

interface SetRoleProps {
  rolesInfo: {
    availableRoles: PresenceRole[];
    currentRoleId: string;
  };
  msTeamsContext: microsoftTeams.app.Context | null;
  actions: any;
}

interface SetRolePageState {}

export class SetRole extends React.Component<SetRoleProps, SetRolePageState> {
  constructor(props: SetRoleProps) {
    super(props);
    this.props.actions.loadUserRolesInfo();
    this.onRoleSelect = this.onRoleSelect.bind(this);
    this.roleToString = this.roleToString.bind(this);
  }

  get currentRole(): string {
    const rolesInfo = this.props.rolesInfo;
    const currentRole = rolesInfo.availableRoles.find((role) => role.id === rolesInfo.currentRoleId);
    return currentRole?.stateName as string;
  }

  onRoleSelect(event: any, data: OptionOnSelectData) {
    const role = this.props.rolesInfo.availableRoles.find((role) => role.stateName === data.optionValue);
    this.props.actions.updateCurrentUserRole(role?.id);
  }

  roleToString(data: any) {
    return data.stateName;
  }

  render() {
    return (
      !!this.props.rolesInfo.currentRoleId && (
        <Flex column gap="gap.smaller">
          <Flex space="between" vAlign="end">
            <Subtitle2Stronger>{setRole.setYourRole}</Subtitle2Stronger>
            <Button
              size="medium"
              icon={<ArrowSync16Filled />}
              onClick={() => {
                this.props.actions.loadUserRolesInfo;
              }}
            />
          </Flex>
          <Dropdown onOptionSelect={this.onRoleSelect} defaultValue={this.props.rolesInfo.currentRoleId}>
            {this.props.rolesInfo.availableRoles?.map((r) => (
              <Option key={r.id} value={r.stateName} text={r.stateName}>
                {r.stateName}
              </Option>
            ))}
          </Dropdown>
        </Flex>
      )
    );
  }
}

const mapStateToProps = (state: any) => ({
  callerAliases: userAliasesSelector(state),
  rolesInfo: userRolesInfoSelector(state),
  msTeamsContext: msTeamsContextSelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      ...userDataActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetRole);
