import * as commonActionCreators from '../action-creators/common-action-creators';
import {
  getPermissions,
  getPresenceActivityList,
  getPresenceActivitiesMapping,
  setPresenceActivitiesMapping,
  getPresenceActivitiesDefaultMapping,
  setPresenceActivitiesDefaultMapping,
  deleteUserSettings,
  getVoicemailSettings,
  setVoicemailSettings,
} from '../../api/settingsApi';
import { BaseAction } from '../model/action';
import httpStatusCodes from '../../constants/http-status-codes';
import * as actionCreators from '../action-creators/settings-data-action-creators';
import UserPresenceMapping from '../../models/user-presence-mapping';
import { app, UserTeamRole } from '@microsoft/teams-js';
import { endpoints, msTeamsSetting } from '../../config/endpoints';
import VoicemailSettings from '../../models/voicemail-settings';

const loadPermissions = () => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await getPermissions();
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetPermissions(response.data.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const setTestUserList = (users: string[]) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(actionCreators.acSetTestUserList(users));
  } catch (error) {
    console.error(error);
  }
};

const setTeamsData = (context: app.Context) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(actionCreators.acAdminRole(context.team?.userRole === UserTeamRole.Admin));
    const addPermissionsUrl =
      `https://login.microsoftonline.com/${context.user?.tenant?.id}/oauth2/v2.0/authorize?` +
      `client_id=${msTeamsSetting.clientId}&response_type=code&redirect_uri=${endpoints.backEndBase}` +
      `/auth&response_mode=query&prompt=select_account&scope=Directory.Read.All Presence.Read.All Presence.ReadWrite` +
      `&state=B70E784F-B481-4A4C-B822-5CA339298B9D::${context.user?.tenant?.id}::${context.user?.id}::GlobalScope`;
    const addSearchPermissionsUrl =
      `https://login.microsoftonline.com/${context.user?.tenant?.id}/oauth2/v2.0/authorize?` +
      `client_id=${msTeamsSetting.clientId}&response_type=code&redirect_uri=${endpoints.backEndBase}` +
      `/auth&response_mode=query&prompt=select_account&scope=Contacts.Read` +
      `&state=B70E784F-B481-4A4C-B822-5CA339298B9D::${context.user?.tenant?.id}::${context.user?.id}::SearchContactsScope`;
    dispatch(actionCreators.acAdminRole(context.team?.userRole === null || context.team?.userRole === UserTeamRole.Admin));
    dispatch(actionCreators.acGraphUrls(addPermissionsUrl, addSearchPermissionsUrl));
  } catch (error) {
    console.error(error);
  }
};

const loadPresenceActivitiesMapping = () => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const paListResponse = await getPresenceActivityList();
    if (paListResponse.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetPresenceActivityList(paListResponse.data));
      const paMappingResponse = await getPresenceActivitiesMapping();
      if (paMappingResponse.status === httpStatusCodes.OK) {
        dispatch(actionCreators.acSetPresenceActivitiesMapping(paMappingResponse.data));
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const savePresenceActivitiesMapping =
  (mappings: UserPresenceMapping[]) => async (dispatch: (action: BaseAction) => void) => {
    try {
      dispatch(commonActionCreators.acSetLoadingIndicator(true));
      const response = await setPresenceActivitiesMapping(mappings);
      if (response.status === httpStatusCodes.OK) {
        dispatch(actionCreators.acSetPresenceActivitiesMapping(mappings));
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(commonActionCreators.acSetLoadingIndicator(false));
    }
  };

const loadPresenceActivitiesDefaultMapping = () => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const paListResponse = await getPresenceActivityList();
    if (paListResponse.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetPresenceActivityList(paListResponse.data));
      const paMappingResponse = await getPresenceActivitiesDefaultMapping();
      if (paMappingResponse.status === httpStatusCodes.OK) {
        dispatch(actionCreators.acSetPresenceActivitiesDefaultMapping(paMappingResponse.data));
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const savePresenceActivitiesDefaultMapping =
  (mappings: UserPresenceMapping[]) => async (dispatch: (action: BaseAction) => void) => {
    try {
      dispatch(commonActionCreators.acSetLoadingIndicator(true));
      const response = await setPresenceActivitiesDefaultMapping(mappings);
      if (response.status === httpStatusCodes.OK) {
        dispatch(actionCreators.acSetPresenceActivitiesDefaultMapping(mappings));
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(commonActionCreators.acSetLoadingIndicator(false));
    }
  };

const resetToDefault = () => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await deleteUserSettings();
    if (response.status === httpStatusCodes.OK) {
      const paMappingResponse = await getPresenceActivitiesMapping();
      if (paMappingResponse.status === httpStatusCodes.OK) {
        dispatch(actionCreators.acSetPresenceActivitiesMapping(paMappingResponse.data));
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const saveVoicemailSettings = (settings: VoicemailSettings) => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const response = await setVoicemailSettings(settings);
    if (response.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetVoicemailSettings(settings));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

const loadVoicemailSettings = () => async (dispatch: (action: BaseAction) => void) => {
  try {
    dispatch(commonActionCreators.acSetLoadingIndicator(true));
    const settingsResponse = await getVoicemailSettings();
    if (settingsResponse.status === httpStatusCodes.OK) {
      dispatch(actionCreators.acSetVoicemailSettings(settingsResponse.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(commonActionCreators.acSetLoadingIndicator(false));
  }
};

export {
  loadPermissions,
  loadPresenceActivitiesMapping,
  savePresenceActivitiesMapping,
  resetToDefault,
  loadPresenceActivitiesDefaultMapping,
  savePresenceActivitiesDefaultMapping,
  setTeamsData,
  setTestUserList,
  saveVoicemailSettings,
  loadVoicemailSettings,
};
