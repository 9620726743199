import axios from 'axios';
import { endpoints } from '../config/endpoints';
import ResponseModel from '../models/response-model';
import AppPermissions from '../models/app-permissions';
import PresenceActivity from '../models/presence-activity';
import UserPresenceMapping from '../models/user-presence-mapping';
import VoicemailSettings from '../models/voicemail-settings';

function getPermissions() {
  const url = `${endpoints.backEndBase}/settings/permissions`;
  return axios.get<ResponseModel<AppPermissions>>(url);
}

function getPresenceActivityList() {
  const url = `${endpoints.backEndBase}/settings/presences`;
  return axios.get<PresenceActivity[]>(url);
}

function getPresenceActivitiesMapping() {
  const url = `${endpoints.backEndBase}/settings/mapping`;
  return axios.get<UserPresenceMapping[]>(url);
}

function setPresenceActivitiesMapping(body: UserPresenceMapping[]) {
  const url = `${endpoints.backEndBase}/settings/mapping`;
  return axios.post<boolean>(url, body);
}

function getPresenceActivitiesDefaultMapping() {
  const url = `${endpoints.backEndBase}/settings/mapping/default`;
  return axios.get<UserPresenceMapping[]>(url);
}

function setPresenceActivitiesDefaultMapping(body: UserPresenceMapping[]) {
  const url = `${endpoints.backEndBase}/settings/mapping/default`;
  return axios.post<boolean>(url, body);
}

function deleteUserSettings() {
  const url = `${endpoints.backEndBase}/settings/mapping/reset`;
  return axios.delete<boolean>(url);
}

function getVoicemailSettings() {
  const url = `${endpoints.backEndBase}/settings/voicemail`;
  return axios.get<VoicemailSettings>(url);
}

function setVoicemailSettings(body: VoicemailSettings) {
  const url = `${endpoints.backEndBase}/settings/voicemail`;
  return axios.put<boolean>(url, body);
}

export {
  getPermissions,
  getPresenceActivityList,
  getPresenceActivitiesMapping,
  setPresenceActivitiesMapping,
  getPresenceActivitiesDefaultMapping,
  setPresenceActivitiesDefaultMapping,
  deleteUserSettings,
  getVoicemailSettings,
  setVoicemailSettings,
};
